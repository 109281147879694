#spectre {
  .row {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
  }

  .column {
    min-height: 75px !important;
    flex-grow: 1;
    flex-basis: 100%;
    overflow: auto;
  }

  .editor-clm {
    display: flex;
    flex-direction: column;
  }

  .gjs-cv-canvas {
    width: 100%;
    height: 100%;
    top: 0;
  }

  #gjs {
    height: 100vh;
  }

  .gjs-cv-canvas {
    width: 100% !important;
  }
  .gjs-pn-buttons {
    justify-content: space-around;
  }

  .image-circle img {
    border-radius: 50%;
  }

  .gjs-pn-views-container {
    display: none !important;
  }

  .column,
  [class~="col-"] {
    padding-left: 0px;
    padding-right: 0px;
  }

  .gjs-trt-trait--button-size .gjs-label-wrp {
    display: none;
  }

  .gjs-field-wrp--button-size .gjs-btn-prim {
    margin: 2px;
    width: 50%;
  }

  .gjs-field-wrp--button-size .gjs-btn-prim:hover {
    background-color: #010101;
  }

  .gjs-field-wrp--button-size .gjs-btn-prim.active {
    background-color: #010101;
  }

  .tab.tab-block .tab-item a {
    padding: 10px;
  }

  .tab.tab-block .tab-item a:hover {
    color: #010101;
  }

  .tab .tab-item a.active,
  .tab .tab-item.active a {
    color: #010101;
    font-weight: bolder;
    border-bottom: 3px solid #010101 !important;
  }

  .gjs-four-color-h:hover {
    color: #010101 !important;
    font-weight: bolder;
  }

  .gjs-one-bg {
    background-color: #fff !important;
  }

  .gjs-two-color {
    color: #5a5a5a !important;
  }

  .gjs-pn-commands {
    width: 100% !important;
  }

  .gjs-pn-options {
    right: 0px;
  }

  .gjs-four-color {
    color: #010101;
  }

  .gjs-block-categories {
    margin-top: 10px;
  }

  .gjs-block-category .gjs-title {
    padding: 12px 20px;
    border-top: solid 1px #dfe3e6;
    border-bottom: solid 1px #dfe3e6 !important;
    font-size: 16px;
    font-weight: 100;
    color: #505050;
    background-color: #f7f7f7 !important;
  }

  .gjs-sm-sector-title {
    font-weight: bolder;
    padding: 12px 20px;
    border-top: solid 1px #dfe3e6;
    border-bottom: solid 1px #dfe3e6 !important;
    font-size: 14px;
    color: rgb(96, 106, 114);
    background-color: #f7f7f7 !important;
  }

  .gjs-block-category .gjs-title:hover {
    background-color: transparent !important;
  }

  .gjs-block-category .gjs-title .gjs-caret-icon {
    float: right;
  }

  #gjs-clm-new {
    color: #010101 !important;
  }

  .fr-buttons {
    display: none !important;
  }

  .fr-toolbar.fr-top {
    box-shadow: 0 4px 11px #555d6642;
    border: none;
    border-radius: 4px;
  }

  .gjs-frame {
    margin-top: 65px;
    margin-left: 12px;
    width: 98% !important;
  }

  .gjs-frame-wrapper {
    background-color: #ffffff;
  }

  .gjs-four-color {
    color: #010101 !important;
  }

  .gjs-block-category.gjs-open {
    border-bottom: none;
  }

  #style-manager {
    border-right: 1px solid #ccc;
  }

  .gjs-block {
    width: 28%;
    font-size: 24px;
  }
  .gjs-sm-properties {
    font-size: 14px;
  }

  .gjs-color-warn {
    color: #000;
    fill: #000;
    font-weight: bolder;
  }

  #traits-container {
    margin: 15px 0px;
  }

  .gjs-trt-traits {
    font-size: 14px;
  }

  .gjs-btn-prim {
    color: #212529 !important;
    background-color: #e2e6ea !important;
    border-color: #dae0e5 !important;
  }

  .gjs-block-label {
    font-size: 12px;
    color: #505050 !important;
  }

  .gjs-btn-import {
    margin-top: 5px;
  }

  .gjs-cm-editor #gjs-cm-title {
    background-color: rgb(23, 23, 23) !important;
  }

  .gjs-cm-editor#gjs-cm-htmlmixed #gjs-cm-title,
  .gjs-cm-editor#gjs-cm-css #gjs-cm-title {
    color: #d5d3d5;
  }

  .cm-s-hopscotch.CodeMirror {
    background-color: rgb(23, 23, 23) !important;
    color: #d5d3d5;
  }

  .cm-s-hopscotch .CodeMirror-gutters {
    background-color: rgb(23, 23, 23) !important;
  }

  .gjs-resizer-h {
    border-color: #000 !important;
  }
  .gjs-toolbar {
    background-color: #000 !important;
  }

  .gjs-com-badge,
  .gjs-com-badge-red,
  .gjs-badge {
    background-color: #000 !important;
  }

  .gjs-cv-canvas .gjs-highlighter,
  .gjs-cv-canvas .gjs-highlighter-sel {
    outline: 1px solid #000 !important;
  }

  .gjs-rte-action {
    background: #000;
    color: #fff;
    border-right: 1px solid #fff;
  }

  .gjs-rte-action:hover {
    background: #010101b5;
  }

  /*  */
  .gjs-block::before {
    color: #8d2de0 !important;
  }

  .gjs-block__media {
    color: #8d2de0 !important;
  }

  .gjs-pn-btn {
    color: #8d2de0 !important;
  }

  .custom-save-button {
    border: solid 1px;
    font-size: 14px;
  }

  .loading-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgb(249, 247, 247);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
