.frame-fill {
  zoom: 1.45;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.75);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: 0 0;
}

.template_type_label_email {
  background: #00bcd4;
  padding: 4px 6px;
  color: white;
  border-radius: 8px;
}

.template_type_label_web {
  background: #009688;
  padding: 4px 6px;
  color: white;
  border-radius: 8px;
}

.modal_setter .modal-content {
  overflow-y: auto;
}
