// toggle
div.toggle-btn {
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    margin-top: 2px;
    width: 28px;
    height: 15px;
    align-self: center;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// color pickr container

.color-pickr-container {
  margin-top: 15px;
  display: flex;
  align-items: center;

  button {
    border: 0.5px solid black;
  }

  span {
    margin-top: 2px;
    margin-left: 10px;
  }

  span.color-tip {
    margin-left: 0px;
    margin-bottom: 2px;
    width: 16;
    height: 16;
  }
}

// estilização para ? onde serão mostrada as dicas

.show-tips {
  margin-left: 5px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: 1px solid #b3b5c2;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b3b5c2;
}

// collapsable menu

.personalization-section {
  margin: 15px 10px 0px 0px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

// inside pages btns

.header-btns-container {
  display: flex;
  justify-content: end;
  align-items: center;

  * {
    @media (max-width: 1200px) {
      height: 50px;
    }
    margin-left: 5px;
  }
}

// export progress bar
.export-progress-container {
  height: 0.8rem;
  width: 100%;
  border-radius: 0.4rem;

  background: #f2f2f2;

  .export-progress {
    height: 100%;
    width: 0;
    border-radius: 0.4rem;

    background: #9bca61;

    transition: width 0.45s ease;
  }
}

// bottom navigation

.bottom-navigation {
  display: flex;
  align-items: start;
}

.mobile-btn-section {
  display: none;
}

// tags
.tag-container {
  display: flex;
  height: 25px;

  .tag-wrapper {
    margin: 5px 5px 0 0;

    span {
      padding: 3px;
      background-color: hsl(0, 0%, 90%);
    }

    i {
      padding: 3px;
      background-color: hsl(0, 0%, 90%);
      cursor: pointer;
    }

    i:hover {
      background-color: #ff668f;
    }
  }
}

.layout-menu-mobile {
  display: none;
}

.option-drop-menu {
  margin-right: 10px;
}

@media (max-width: 900px) {
  .layout-menu-mobile {
    display: block;
  }

  .layout-menu-web {
    display: none;
  }

  .bg-primary {
    #main-header {
      justify-content: center !important;
      .col-7 {
        width: 100% !important;
        z-index: 2;
      }
      .col-2 {
        position: absolute;
        right: 0;
        width: 35% !important;
        z-index: 1;
      }
    }
  }
  .mobile-btn-section {
    display: block;
  }
  .header-btns-container {
    display: none;
  }

  .mobile-btns-top {
    margin-bottom: 10px;
    font-weight: 500;
  }

  .bottom-navigation {
    flex-direction: column;
    align-items: center;

    .pagination-infos {
      width: 247px;
      align-items: center;
      margin: auto;
    }

    nav {
      margin-top: 15px;

      ul {
        margin: auto;
      }

      .dropdown-toggle {
        display: none;
      }
    }
  }

  #page-header-search-dropdown {
    display: none;
  }

  .dropdown-menu {
    &.show {
      top: 70px !important;
    }
  }
}

// Custom Form Styles
.dragItems {
  margin: auto;
}

.draggable-container {
  margin: 15px 12px;
  border: 1px solid black;
  border-radius: 15px;

  .draggable-inputs {
    margin-bottom: 10px;
    font: bold 16px;
    cursor: pointer;
    color: #495057;

    &:hover {
      background-color: #efefef;
    }
  }
}

.add-row-button {
  padding: 12px;
  margin: auto;
  width: 80%;
}

.entries-options {
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
}

.select-input-btn {
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: #495057;
}

@media (max-width: 900px) {
  .draggable-container {
    margin: auto;
    margin-bottom: 15px;
    width: 80%;
  }
  .dragItems {
    margin: auto;
    width: 100%;
  }
  .add-row-button {
    margin: auto;
    width: 80%;
  }

  .row-wrapper-form {
    display: flex;
    flex-direction: column;
  }
}

.dragItem {
  border: 1px solid #ccc;
  border-style: dashed;
  min-height: 50px;
  margin: 1px;

  &:hover {
    border: 1px solid black;
    border-style: dashed;
  }
}

.close-icon {
  cursor: pointer;
  font-size: 18px;
  color: red;
  font-weight: 900;
}

.row-config-container {
  display: flex;
  justify-content: flex-end;

  i {
    cursor: pointer;
    margin-left: 5px;
  }
}

.custom-form-wrapper {
  margin-top: 20px;
}

.name-custom-form {
  font-weight: 700;
  color: black;
}

.custom-form-mb {
  margin-bottom: 10px;
}

.save-btn-form-container {
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-content: flex-start;
}

.save-btn-form {
  width: 80px;
  margin-top: 10px;
}

.input-margin-btm {
  margin-bottom: 20px;
}

.input-mg-rgt {
  margin-right: 10px;
}

.config-form-icon {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;

  span {
    cursor: pointer;
  }
}

.main-question-options {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
}

.custom-input-container {
  margin: 15px 0;
}

.displayed-config-container {
  display: flex;
  justify-content: space-between;

  .displayed-label {
    display: flex;
    align-self: end;
  }
}

// Dashboard

.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.back-to-dashboard {
  display: flex;
  align-items: center;
  color: #5470c6;
  font-size: 20px;
  margin-left: 20px;

  i {
    cursor: pointer;
  }

  span {
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
  }
}

.dashboard-to-ranking {
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
}

.dashboard-access-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 530px) {
  .dashboard-access-footer {
    flex-direction: column;
    align-items: flex-end;
  }
}

// RankChart

.rank-wrapper {
  padding: 0 2px;
  height: 367px;

  .title {
    font-size: 20px;
    color: black;
    font-family: sans-serif;
    font-weight: 700;
  }

  span {
    font-size: 13px;
    color: #494949;
    font-family: sans-serif;
  }

  .sub-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;

    i {
      color: #fac858;
      font-size: 30px;
      margin-bottom: 10px;
    }
    span {
      font-size: 15px;
      font-weight: 700;
    }
  }

  .without-point {
    height: 10px;
    cursor: pointer;
  }

  .point-container {
    align-items: center;
    display: flex;
  }

  .with-point {
    height: 10px;
    cursor: pointer;
  }

  .margin-ranking {
    margin-bottom: 85px;
  }

  @media screen and (max-width: 768px) {
    .margin-ranking {
      margin-bottom: 50px;
    }
  }
}

// Custom Titles

[data-title-withoutpoint]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title-withoutpoint]:after {
  content: attr(data-title-withoutpoint);
  font-size: 1em;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2em;
  white-space: nowrap;
  border: 1px solid #ee6666;
  left: 40%;
  z-index: 99999;
  visibility: hidden;
  border-radius: 3px;
  background: white;
}
[data-title-withoutpoint] {
  position: relative;
}

[data-title-withpoint]:hover:after {
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title-withpoint]:after {
  content: attr(data-title-withpoint);
  font-size: 1em;
  position: absolute;
  padding: 1px 5px 2px 5px;
  bottom: -2em;
  white-space: nowrap;
  border: 1px solid #91cc75;
  left: 40%;
  z-index: 99999;
  visibility: hidden;
  border-radius: 3px;
  background: white;
}
[data-title-withpoint] {
  position: relative;
}

// Line Break

.line-break-rank {
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin-top: 5px;
}

// Work in Progress
.work-in-progress-wrapper {
  margin-top: 10%;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 101%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);

  span {
    color: black;
    font-weight: 700;
    font-size: 1.2em;
  }
}

.work-in-progress-container {
  margin: 0 auto;
  width: 25%;
  padding: 4px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.work-in-progress-bar {
  height: 15px;
  width: 70%;
  border-radius: 4px;
  background: repeating-linear-gradient(
    45deg,
    #606dbc,
    #606dbc 10px,
    #465298 10px,
    #465298 20px
  );
}

// Brazil Map
.brazilian-state:hover {
  filter: invert(64%) sepia(100%) saturate(1747%) hue-rotate(358deg)
    brightness(99%) contrast(107%);
}

.state-without-sale {
  fill: #ffebcd !important;
}

.state-with-medium-sale {
  fill: #ffae37 !important;
}

.state-with-high-sale {
  fill: #c87700 !important;
}

.map-brazil {
  padding: 0 2px;

  .title {
    font-size: 18px;
    color: black;
    font-family: sans-serif;
    font-weight: 700;
  }
}
