// main.scss
@import "./spectre.scss";

// Aumenta a especificidade para custom.css
body {
  @import "./custom.scss";

  .gjs-layer-name {
    padding: 14px 0 !important;
    display: inline-block !important;
    box-sizing: content-box !important;
    overflow: visible !important;
    white-space: nowrap !important;
    margin: 0 30px 0 5px !important;
    max-width: 170px !important;
  }
}
