.modal {
  overflow: hidden;
}
.modal-content {
  overflow-y: scroll;
  max-height: 80vh;
}
.modal {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.btn-normal {
  transition: 0.2s;
  height: 40px;
  width: 120px;
}

.btn-retracted {
  transition: 0.2s;
  height: 40px;
  width: 41px;
  border-radius: 23px;
}

.spinner-loading {
  width: 20px;
  height: 20px;
  top: 2px;
  left: -2px;
  position: relative;
}

.center-itens {
  justify-content: center;
}

.splash-screen {
  transition: 1s;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

.logo-img {
  transition: 1s;
  position: absolute;
  width: 156px;
  left: 46px;
  top: 0px;
}

.centered-logo-img {
  transition: 1s;
  position: absolute;
  width: 156px;
  left: 50vw;
  top: 50vh;
}

.splash-login-screen {
  transition: 1s;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #f8f8fb;
  position: absolute;
}

.img-login-card {
  transition: 1s;
  position: absolute;
  top: -84px;
  left: -23px;
  width: 156px;
}

.img-login-card-centered {
  transition: 1s;
  position: absolute;
  width: 156px;
  left: 0vw;
  top: 20vh;
  z-index: 9999;
}

.overview-splash-show {
  transition: 0.5s;
  opacity: 1;
  display: "block";
}

.overview-splash-hidden {
  transition: 0.5s;
  opacity: 0;
  display: none;
  z-index: -9999;
}

//Effect Scale
.modal {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.scale-effect {
  animation: scaleContent 0.5s;
  overflow: hidden;
}

@keyframes scaleContent {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/////////////

//Effect Position
.pages-effect {
  animation: positionContent 0.5s;
  overflow: hidden;
}

@keyframes positionContent {
  0% {
    opacity: 0;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
  100% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
}

.responsive-modal {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.toast-success {
  background-color: rgb(52, 195, 143) !important;
  border: 2px solid rgb(52, 195, 143) !important;
}

.error-label {
  color: #f46a6a;
}
/////////////

/// Animation Button
@keyframes stepToShowSpin {
  0% {
    opacity: 0;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
  100% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.btn {
  transition: all 0.5s;
}
.btn-animated {
  transform: rotate(-180deg);
  width: 36.03px !important;
  height: 36.03px !important;
  min-width: 0px;
  border-radius: 41px;
  justify-content: center;
  display: flex;
}
.btn-animated span {
  display: none;
}
.btn-animated .no-loading {
  display: none;
}

.fix-position {
  position: absolute;
  top: 20%;
  left: 20%;
}
.letter-avatar-lg {
  background: #09426a;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  color: #a1cf5f;
  font-weight: 200;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.letter-avatar {
  background: #09426a;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  color: #a1cf5f;
  font-weight: 200;
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
}
.letter-btn {
  display: flex;
  padding: 18px;
}
.fix-chevron {
  position: relative;
  top: 3px;
  left: 4px;
  font-size: 18px;
}
.quality-low {
  height: 3px;
  width: 10%;
  background: #f46a6a;
}
.quality-medium {
  height: 3px;
  width: 10%;
  background: #f1b44c;
}
.quality-rigth {
  height: 3px;
  width: 10%;
  background: #198754;
}
.fix-p {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.eye-open {
  font-size: 22px;
  position: absolute;
  top: 30px;
  right: 16px;
}
.btn-bottom-middle {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: -28px;
  padding-bottom: 11px;
}
.closed-caption {
  background-color: #dc3545;
  color: #ffffffd4;
  border-radius: 11px;
  padding: 4px;
  font-size: 12px;
}
.opened-caption {
  background-color: #198754;
  color: #ffffffde;
  border-radius: 11px;
  padding: 4px;
  font-size: 12px;
}

.loading-effect-files-0:after {
  content: "\A";
  position: absolute;
  background: #0000002e;
  transition: 2s;
  top: 0;
  bottom: 0;
  left: 0;
}

.react-tagsinput {
  padding-top: 0px !important;
  border-radius: 4px !important;
}

.react-autosuggest__suggestions-list {
  position: absolute;
  background: white;
  z-index: 2;
  width: 89%;
  border: 1px solid #cccccc;
  border-radius: 3px;
}
#react-autowhatever-1 ul {
  padding-left: 0px !important;
}
#react-autowhatever-1 li {
  list-style: none;
  padding: 19px;
  cursor: pointer;
}
#react-autowhatever-1 li:hover {
  background: #10476e26 !important;
}
.react-tagsinput-tag {
  margin-top: 2px;
}
/////
