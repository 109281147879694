// links da navbar horizontal do event
ul.nav {
  li.nav-item {
    transition: all 0.4s;
    margin: 3px;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    // organiza icone com texto
    a.nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      color: $body-color;

      i {
        font-size: 14px;
      }
    }

    &:hover {
      background-color: $menu-item-hover-color;
    }
  }

  // estilos pra quando o botão está selecionado
  li.nav-item.active {
    background-color: $sidebar-menu-item-active-color;

    a.nav-link {
      color: #fff;
    }

    &:hover {
      background-color: $sidebar-menu-item-active-color;
    }
  }

  div.dropdown {
    // transition: all .4s;
    margin: 3px;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    a.dropdown-toggle.nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      color: $body-color;
      i {
        font-size: 14px;
      }
    }
    a.dropdown-toggle.nav-link.active {
      background-color: $sidebar-menu-item-active-color;
      color: #fff;
    }
  }
  div.dropdown.show {
    transition: all 0.4s;
    margin: 3px;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $sidebar-menu-item-active-color;

    a.dropdown-toggle.nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      color: #fff;
      background-color: $sidebar-menu-item-active-color;
    }
  }
}

.sw-error-icon-collpase {
  i {
    font-size: 20px;
    cursor: pointer;
  }
}
.sw-error-card {
  margin-top: 20px;
  max-height: 150px;
  .sw-card-body {
    text-align: left;
    padding: 5px;
    overflow-y: auto;
  }
  .table {
    text-align: left;
    thead {
      background-color: #c4c3d0;
    }
  }
}
.events-input-search-select {
  width: 33%;
}

.stock-card-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: center;
  margin: 20px 0;
}

.card-stock {
  margin: 5px;
  text-align: center;
}

.card-stock span {
  color: white;
}

.card-body-stock {
  text-align: center;
  margin: 0px;
  padding: 5px;
}

.card-body-stock p {
  font-size: 1.1rem;
  font-weight: 100;
  margin: 0px;
  color: rgb(255, 255, 255);
}
.card-stock-subtitle {
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  margin-top: 5px;
  margin-bottom: 3px;
}
.card-stock-subtitle p:nth-of-type(1) {
  width: 49%;
  text-align: center;
  font-size: 0.9rem;
  margin: 0px;
  font-weight: 100;
}

.card-stock-subtitle p:nth-of-type(2) {
  width: 1%;
  font-size: 0.9rem;
  margin: 0px;
}

.card-stock-subtitle p:nth-of-type(3) {
  width: 49%;
  text-align: center;
  font-size: 0.9rem;
  margin: 0px;
  font-weight: 100;
}

.card-text-stock {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  width: 80%;
  margin-left: 10%;
}

.card-text-stock p {
  width: 50%;
  text-align: center;
  font-size: 0.9rem;
  margin: 0px;
  font-weight: 100;
}

.search-filters {
  padding: 10px;
  margin-left: 5px;
  border: 1px solid #c8c8c8;
  border-radius: 10px;
}
.search-filters span {
  display: flex;
  flex-direction: row;
}
.search-item {
  width: auto;
  // max-width: 140px;
}
.search-filters input {
  margin-right: 5px;
}

.challenge-container {
  margin-bottom: 10px;
  width: 10%;
  justify-content: flex-start;
}

.reports-button-add {
  margin-left: 10px;
  height: 35px;
  margin-top: 10px;
}

@media (max-width: 780px) {
  .card-body-stock p {
    font-size: 0.8rem;
  }

  .card-stock-subtitle p:nth-of-type(1) {
    font-size: 0.6rem;
  }

  .card-stock-subtitle p:nth-of-type(2) {
    font-size: 0.6rem;
  }

  .card-stock-subtitle p:nth-of-type(3) {
    font-size: 0.6rem;
  }

  .card-text-stock {
    font-size: 0.8rem;
  }

  .card-text-stock p {
    font-size: 0.8rem;
  }
  .challenge-container {
    margin-bottom: 10px;
    width: auto;
    justify-content: space-between;
  }
}
@media (max-width: 590px) {
  .stock-card-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: auto;
  }

  .card-body-stock p {
    font-size: 0.8rem;
  }

  .card-stock-subtitle p:nth-of-type(1) {
    font-size: 0.6rem;
  }

  .card-stock-subtitle p:nth-of-type(2) {
    font-size: 0.6rem;
  }

  .card-stock-subtitle p:nth-of-type(3) {
    font-size: 0.6rem;
  }

  .card-text-stock {
    font-size: 0.8rem;
  }

  .card-text-stock p {
    font-size: 0.8rem;
  }
  .challenge-container {
    margin-bottom: 10px;
    width: auto;
    justify-content: space-between;
  }
}
